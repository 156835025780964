import React, { useEffect, useState } from "react";
import { pdfjs, Document, Page as ReactPdfPage } from "react-pdf";
import HTMLFlipBook from "react-pageflip";
import { useTranslation } from "react-i18next";
import { useMediaQuery } from "react-responsive";
import "react-pdf/dist/Page/AnnotationLayer.css";
import "react-pdf/dist/esm/Page/TextLayer.css";
import pdf from "./Logbuch.pdf";

const MyBook = () => {
  const array = Array.from({ length: 10 }, (_, index) => index + 1);
  const { t } = useTranslation();
  const isWideScreen = useMediaQuery({ minWidth: 1200 });
  const isPortrait = useMediaQuery({ maxWidth: 1199 });
  const [windowWidth, setWindowWidth] = useState(window.innerWidth);
  pdfjs.GlobalWorkerOptions.workerSrc = `//cdnjs.cloudflare.com/ajax/libs/pdf.js/${pdfjs.version}/pdf.worker.js`;

  useEffect(() => {
    const handleResize = () => {
      setWindowWidth(window.innerWidth);
    };

    window.addEventListener("resize", handleResize);

    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);

  const calculatePdfWidth = () => (isWideScreen ? 480 : windowWidth * 0.8);
  const calculatePdfHeight = () =>
    isWideScreen ? 480 * Math.sqrt(2) : windowWidth * 0.8 * Math.sqrt(2);

  const renderPdfPages = () =>
    array.map((index) => (
      <div key={index}>
        <Document file={pdf}>
          <ReactPdfPage
            pageNumber={index}
            className="pdf-page"
            width={calculatePdfWidth()}
            height={calculatePdfHeight()}
          />
        </Document>
      </div>
    ));

  return (
    <div className={`aboutUs ${isPortrait ? "portrait" : ""}`}>
      <h2>{t("aboutUs.logbuch")}</h2>
      <div
        style={{ width: calculatePdfWidth(), height: calculatePdfHeight() }}
        className={`flipbook-container ${isPortrait ? "portrait" : ""}`}
      >
        <HTMLFlipBook
          key={`${calculatePdfWidth()}-${calculatePdfHeight()}-${isPortrait}`}
          style={{
            minHeight: 0,
            height: calculatePdfHeight(),
          }}
          usePortrait={isPortrait}
          width={calculatePdfWidth()}
          height={calculatePdfHeight()}
          maxShadowOpacity={0.2}
          showCover
        >
          {renderPdfPages()}
        </HTMLFlipBook>
      </div>
    </div>
  );
};

export default MyBook;
