import React, { useState, useEffect } from "react";
import { useTranslation } from "react-i18next";
import About from "../../components/about/about";
import "./home.css";
import Info from "../../components/info/info";
import Termine from "../../components/termine/termine";
import Slideshow from "../../components/slideshow/slideshow";

function Home() {
  const [t] = useTranslation();
  const [images, setImages] = useState([]);

  useEffect(() => {
    // Fetch the image URLs from the API
    fetch("/server/api/startimage/?format=json")
      .then((response) => response.json())
      .then((data) => {
        // Assuming the response contains an array of image URLs
        console.log(data);
        setImages(data.map(item => item.bild));
      })
      .catch((error) => {
        console.error("Error fetching the images:", error);
      });
  }, []);

  return (
    <div className="home">
      <Slideshow images={images}>
        <h1>{t("home.header.h1")}</h1>
        <h2>{t("home.header.h2")}</h2>
        {/*<button className="button">{t("home.header.button")}</button>*/}
      </Slideshow>

      <About t={t} />
      <Info t={t} />
      <Termine t={t} />
    </div>
  );
}

export default Home;
