import React, {useEffect} from "react";
import { useTranslation } from "react-i18next";

function NotFound() {
  const [t] = useTranslation();
   useEffect(() => {
    document.title = 'Error 404 - Tübinger NeckarSegler'; 
  }, [t]);
  return (
    <div className="imprint">
      <h1>{t("notfound.headline")}</h1>
      <p>{t("notfound.text")}</p>
    </div>
  );
}

export default NotFound;
