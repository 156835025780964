import React, { useEffect, useState } from "react";
import PhotoGallery from "./PhotoGallery";
import { useTranslation } from "react-i18next";
import './gallery.css';

const initialPhotos = [];

function Gallery() {
  const { t } = useTranslation();
  const [photos, setPhotos] = useState(initialPhotos);

  useEffect(() => {
   

    fetch("/server/api/gallery/?format=json")
      .then((response) => response.json())
      .then((data) => {
        

        setPhotos(data);
      })
      .catch((error) => console.error("Error fetching gallery data:", error));
  }, [t]);

  return (
    <div className="gallery">
      <h1>{t("footer.gallery")}</h1>
      <PhotoGallery photos={photos} />
    </div>
  );
}

export default Gallery;
