import React from "react";
import "./info.css";

function Info({ t }) {
  return (
    <div id="info">
      <div class="grid-item dark item1">
        <h4>{t("home.info.fun.h4")}</h4>
        <p>{t("home.info.fun.text")}</p>
        
      </div>
      <div class="item2">
        <img src="img/fun.jpg" alt={t("home.info.fun.h4")} />
      </div>
      <div class="grid-item dark item3">
        <h4>{t("home.info.location.h4")}</h4>
        <p>{t("home.info.location.text")}</p>
        <span
          className="button light"
          onClick={() => {
            document.querySelector("#about").scrollIntoView();
          }}
        >
          {t("home.info.location.link")}
        </span>
      </div>
      <div class="item4">
        <img src="img/tuebingen.jpg" alt={t("home.info.location.h4")} />
      </div>
      <div class="item5">
        <img src="img/event.jpg" alt={t("home.info.events.h4")} />
      </div>
      <div class="grid-item item6">
        <h4>{t("home.info.events.h4")}</h4>
        <p>{t("home.info.events.text")}</p>
        <span
          className="button darker"
          onClick={() => {
            document.querySelector("#term").scrollIntoView();
          }}
          href="#"
        >
          {t("home.info.events.link")}
        </span>
      </div>
      <div class="item7">
        <img src="img/regatta.jpg" alt={t("home.info.regatta.h4")} />
      </div>
      <div class="grid-item item8">
        <h4>{t("home.info.regatta.h4")}</h4>
        <p>{t("home.info.regatta.text")}</p>
        
      </div>
    </div>
  );
}

export default Info;
