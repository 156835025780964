import { useState, useEffect, Children, cloneElement } from "react";

function Slideshow({ images, children }) {
  const [currentImage, setCurrentImage] = useState(0);

  useEffect(() => {
    const interval = setInterval(() => {
      setCurrentImage((currentImage + 1) % images.length);
    }, 10000);
    return () => clearInterval(interval);
  }, [currentImage, images.length]);

  return (
    <div>
      <header
        style={{
          background: `rgba(0, 0, 0, 0.34) url('${images[currentImage]}') no-repeat center center/cover`,
          backgroundBlendMode: "darken",
        }}
      >
        {Children.map(children, (child) => cloneElement(child))}
      </header>
    </div>
  );
}

export default Slideshow;
