import React from 'react'
import './about.css'
import { Link } from 'react-router-dom'

function About({t}) {
  const scrollToTop = (event) => {
    setTimeout(() => {
      window.scrollTo(0,0);
    }, 50);
  };
  return (
    <div id="about">
        <iframe title='Tübinger NeckarSegler'  src="https://www.google.com/maps/embed?pb=!1m14!1m8!1m3!1d1895.914689016717!2d9.059614550157796!3d48.51812940836937!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x4799fba14e0aec15%3A0x17442c5df15e9759!2sT%C3%BCbinger%20NeckarSegler%201957%20e.V.!5e0!3m2!1sde!2sde!4v1683925338368!5m2!1sde!2sde" loading="lazy" referrerPolicy="no-referrer-when-downgrade" />
        <div>
          <h3>{t("navbar.aboutUs")}</h3>
          <p>{t("home.about.p")}</p>
          <div className='buttons'>
          
          <Link onClick={scrollToTop} className="button darker" to="/überuns">{t("navbar.aboutUs")}</Link>
          <a className="button" href="mailto:kontakt@neckarsegler.de">{t("home.about.button")}</a>
          </div>
        </div>
      </div>
  )
}

export default About