import React, { useState, useRef, useEffect, useCallback } from "react";
import { ReactComponent as ArrowLeft } from "../../static/icons/arrow-left.svg";
import { ReactComponent as ArrowRight } from "../../static/icons/arrow-right.svg";
import "./PhotoGallery.css";
import { useTranslation } from "react-i18next";

const PhotoGallery = ({ photos }) => {
  const [preview, setPreview] = useState(null);
  const [, i18n] = useTranslation();
  const previewRef = useRef(null);
  const changePreview = useCallback(
    (index) => {
      let validIndex;
      if (index === photos.length) {
        validIndex = 0;
      } else if (index === -1) {
        validIndex = photos.length - 1;
      } else {
        validIndex = index;
      }
      setPreview({
        ...photos[validIndex],
        index: validIndex,
      });
    },
    [photos]
  );

  useEffect(() => {
    const handleKeyDown = (event) => {
      if (event.keyCode === 37) {
        changePreview(preview.index - 1);
      } else if (event.keyCode === 39) {
        changePreview(preview.index + 1);
      }
    };
    document.addEventListener("keydown", handleKeyDown);
    return () => document.removeEventListener("keydown", handleKeyDown);
  }, [changePreview, preview]);

  return (
    <div className="PhotoGallery">
      
      {preview && (
        <div className="PhotoGallery-preview" ref={previewRef}>
          <ArrowLeft onClick={() => changePreview(preview.index - 1)} />
          <img src={preview.bild} alt={preview.untertitel} />
          <figcaption>
            {preview.captionEn !== "" && i18n.language.includes("en") ? (
              <>{preview.untertitel_en}</>
            ) : (
              <>{preview.untertitel}</>
            )}
          </figcaption>
          <ArrowRight onClick={() => changePreview(preview.index + 1)} />
          <div className="background" onClick={() => setPreview(null)} />
        </div>
      )}
      <div className="PhotoGallery-list">
        {photos.map((photo, index) => (
          <div key={photo.src} onClick={() => changePreview(index)}>
            
              <div>
                <img src={photo.bild} alt={photo.untertitel} />
              </div>
            
          </div>
        ))}
      </div>
    </div>
  );
};

export default PhotoGallery;
