import React, { useState, useEffect } from "react";
import { Link, useLocation } from "react-router-dom";
import { useTranslation } from "react-i18next";

import "./navbar.css";

const scrollToTerm = () => {
  if (document.querySelector("#term") != null)
    document.querySelector("#term").scrollIntoView();
  setTimeout(() => {
    const termElement = document.querySelector("#term");
    termElement.scrollIntoView();
  }, 500);
};

const links = [
  { to: "/", label: "navbar.home" },
  { to: "/galerie", label: "navbar.gallery" },
  { to: "/berichte", label: "navbar.article" },
  { to: "/#term", label: "navbar.termine", onClick: scrollToTerm },
  { to: "/%C3%BCberuns", label: "navbar.aboutUs" },
];

function Navbar() {
  const location = useLocation();
  const currentPath = location.pathname;

  const { t, i18n } = useTranslation();

  const [isMenuOpen, setMenuOpen] = useState(true);

  const toggleMenu = () => {
    setMenuOpen(!isMenuOpen);
  };

  const closeMenu = () => {
    setMenuOpen(false);
  };

  const handleChangeLanguage = () => {
    closeMenu(); // Close the menu when changing language
    if (i18n.language.includes("en")) {
      i18n.changeLanguage("de");
    } else {
      i18n.changeLanguage("en");
    }
  };
  useEffect(() => {
    closeMenu();
  }, [currentPath]);

  return (
    <div className="navbar">
      <div className={`logo ${isMenuOpen ? "logo-open" : ""}`}>
        <Link to="/">
          <img src="img/icon.png" alt="logo" />
        </Link>
      </div>

      <nav className={`${isMenuOpen ? "menu-open" : ""}`}>
        {links.map((link, index) => (
          <Link
            key={index}
            to={link.to}
            className={`a ${currentPath === link.to ? "activeLink" : ""}`}
            onClick={() => {
              closeMenu(); // Close the menu when a link is clicked
              link.onClick && link.onClick(); // Call the onClick function if provided
            }}
          >
            {t(link.label)}
          </Link>
        ))}
        <span className="a language" onClick={handleChangeLanguage}>
          <img
            src={
              i18n.language.includes("en")
                ? "img/flag/english.png"
                : "img/flag/german.png"
            }
            alt="lang"
          />
        </span>
      </nav>

      <div
        className={`menu-toggle ${isMenuOpen ? "toggle-open" : ""}`}
        onClick={toggleMenu}
      >
        <div className="bar"></div>
        <div className="bar"></div>
        <div className="bar"></div>
      </div>
    </div>
  );
}

export default Navbar;
