import React from "react";
import ReactDOM from "react-dom/client";
import App from "./App";
import './i18next';
import { pdfjs } from 'react-pdf';

pdfjs.GlobalWorkerOptions.workerSrc = new URL(
  'pdfjs-dist/build/pdf.worker.min.js',
  import.meta.url,
).toString();


const root = ReactDOM.createRoot(document.getElementById("root"));
root.render(<App />);