import React, { useState, useEffect } from "react";
import "./termine.css";
import { useTranslation } from "react-i18next";
import ICAL from "ical.js";

let lang = "de-DE";

function Termine() {
  const [termine, setTermine] = useState([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const { t, i18n } = useTranslation();
  if (i18n.language.includes("en")) {
    lang = "en-US";
  } else {
    lang = "de-DE";
  }

  useEffect(() => {
    // Aktuelles Datum
    const currentDate = new Date();
    async function fetchData() {
      try {
        const response = await fetch(
          "/proxy/remote.php/dav/public-calendars/nBNQoZ7NEL87L4Mr/?export"
        );

        if (response.ok) {
          const data = await response.text();

          const jcalData = ICAL.parse(data);
          const comp = new ICAL.Component(jcalData);
          const veventComponents = comp.getAllSubcomponents("vevent");
          const futureTermine = veventComponents
            .map((vevent) => {
              const startDateProp = vevent.getFirstPropertyValue("dtstart");
              const endDateProp = vevent.getFirstPropertyValue("dtend");
              const summaryProp = vevent.getFirstPropertyValue("summary");
              const locationProp = vevent.getFirstPropertyValue("location");
              const descriptionProp = vevent.getFirstPropertyValue("description");
              const startDate = startDateProp ? new Date(startDateProp) : null;
              const endDate = endDateProp ? new Date(endDateProp) : null;
              const summary = summaryProp ? summaryProp : null;
              const location = locationProp ? locationProp : null;
              const isAllDay = isAllDayEvent(vevent);
              const description = descriptionProp ? descriptionProp : null;

              return { summary, description, startDate, endDate, isAllDay, location };
            })
            .filter((event) => event.startDate !== null) // Filter out events with missing start dates
            .sort((a, b) => a.startDate - b.startDate); // Sort events by start date in ascending order

          // Filter events based on your criteria (e.g., currentDate)
          const filteredTermine = futureTermine.filter((termin) => {
            const terminDate = new Date(termin.startDate);
            terminDate.setHours(23);
            return currentDate <= terminDate;
          });

          setTermine(filteredTermine);
        } else {
          // Handle unsuccessful response
          setError("Failed to fetch data");
        }
      } catch (e) {
        console.error(e);
        setError("An error occurred");
      } finally {
        setLoading(false);
      }
    }

    fetchData();
  }, []);

  if (loading) {
    return (
      <div id="term" className="termine">
        <div className="loader" />
      </div>
    );
  }

  if (error) {
    return (
      <div id="term" className="error">
        Error: {error}
      </div>
    );
  }

  return (
    <div id="term" className="termine">
      <h3>{t("navbar.termine")}</h3>
      <div className="extra">
        <strong className="red">*</strong>
        {t("termine.info")}
        <a href="mailto:kontakt@neckarsegler.de">kontakt@NeckarSegler.de</a>
      </div>
      <table>
        <thead>
          <tr>
            <th>{t("termine.date")}</th>
            <th>{t("termine.time")}</th>
            <th>{t("termine.event")}</th>
            <th>{t("termine.location")}</th>
          </tr>
        </thead>
        <tbody>
          {termine.map((termin, index) => (
            <tr key={index}>
              <td>
                {formatDate(termin.startDate, termin.endDate, termin.isAllDay)}
              </td>
              <td>
                {formatTime(termin.startDate, termin.endDate, termin.isAllDay)}
              </td>
              <td>
                <div
                  dangerouslySetInnerHTML={{
                    __html: renderSummaryWithStyles(termin.summary),
                  }}/>
                  <div className="description">{termin.description}</div>
              </td>
              <td>{termin.location}</td>
            </tr>
          ))}
        </tbody>
      </table>
    </div>
  );
}

// Function to format the time
function formatTime(date, endDate, isAllDay) {
  if (lang === "de-DE") {
    if (
      isAllDay &&
      date.getDate() === new Date(endDate.getTime() - 86400000).getDate()
    ) {
      return "ganztägig";
    }
    if (date.getDate() !== endDate.getDate()) {
      return `${calculateDurationInDays(date, endDate)}-tägig`;
    }

    if (date) {
      const hours = date.getHours();
      const minutes = date.getMinutes();

      if (minutes === 0) {
        return `${hours}Uhr`;
      } else {
        return `${hours}:${minutes}Uhr`;
      }
    }
    return "";
  } else {
    if (
      isAllDay &&
      date.getDate() === new Date(endDate.getTime() - 86400000).getDate()
    ) {
      return "all day";
    }
    if (date.getDate() !== endDate.getDate()) {
      return `${calculateDurationInDays(date, endDate)} days`;
    }

    if (date) {
      let hours = date.getHours();
      const minutes = date.getMinutes();
      let pm = "am";
      if (hours > 11) pm = "pm";
      if (hours > 12) hours = hours % 12;

      return `${hours}:${minutes < 10 ? "0" : ""}${minutes} ${pm}`;
    }
  }
}

function formatDate(startDate, endDate, isAllDay) {
  if (!startDate) {
    return "";
  }

  const startOptions = {
    weekday: "long",
    year: "numeric",
    month: "2-digit",
    day: "2-digit",
  };

  const endOptions = {
    year: "numeric",
    month: "2-digit",
    day: "2-digit",
  };

  let startDateString = startDate.toLocaleString(lang, startOptions);
  let endDateString;

  if (isAllDay) {
    const adjustedEndDate = new Date(endDate.getTime() - 86400000); // Subtract 24 hours
    endDateString = adjustedEndDate.toLocaleString(lang, endOptions);
    if (startDate.getDate() === adjustedEndDate.getDate())
      return startDateString;
  } else {
    endDateString = endDate.toLocaleString(lang, endOptions);
  }
  if (startDate.getDate() === endDate.getDate()) return startDateString;
  startDateString = startDate.toLocaleString(lang, endOptions);

  return startDateString + " - " + endDateString;
}

function isAllDayEvent(vevent) {
  const dtstart = vevent.getFirstPropertyValue("dtstart");
  // Check if the time component is set to 00:00:00

  if (dtstart.isDate) {
    return true;
  }
  return false;
}
function calculateDurationInDays(startDate, endDate) {
  const millisecondsPerDay = 24 * 60 * 60 * 1000; // Number of milliseconds in a day

  // Calculate the difference in milliseconds
  const timeDifference = Math.abs(endDate.getTime() - startDate.getTime());

  // Convert the difference to days
  const durationInDays = Math.ceil(timeDifference / millisecondsPerDay);

  return durationInDays;
}
function renderSummaryWithStyles(summary) {
  return summary
    .replace(/\\b/g, "<strong>")
    .replace(/\\r/g, '<strong style="color: red;">')
    .replace(/\\b/g, '<strong style="color: blue;">')
    .replace(/\\k/g, "<i>")
    .replace(/\\n/g, "<br>");
}

export default Termine;
