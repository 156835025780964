import React, { useEffect, useState } from "react";
import ReactMarkdown from "react-markdown";
import { useTranslation } from "react-i18next";
import "./datenschutz.css";

function Datenschutz() {
  const [markdownContent, setMarkdownContent] = useState("");
  const { i18n } = useTranslation();

  useEffect(() => {
    const fetchContent = async () => {
      try {
        const response = await fetch(
          "/server/api/texts/?format=json&name=Datenschutzerklaerung"
        );
        if (!response.ok) {
          throw new Error("Failed to fetch content");
        }
        const data = await response.json();
        setMarkdownContent(data.length > 0 ? data[0] : {});
      } catch (error) {
        console.error("Error fetching content:", error);
      }
    };

    fetchContent();
  }, []);
  const currentYear = new Date().getFullYear();
  return (
    <div className="datenschutz">
     <ReactMarkdown
        children={(i18n.language.includes("en")
          ? markdownContent.text_en || ""
          : markdownContent.text || ""
        ).replace(/\[Current Year\]/g, currentYear)}
      />
    </div>
  );
}

export default Datenschutz;
