// importiere BrowserRouter
import { BrowserRouter as Router, Routes, Route } from "react-router-dom";


import './App.css';
import Navbar from "./components/navbar/navbar";
import Home from "./pages/home/home";
import Footer from "./components/footer/footer";
import Datenschutz from "./pages/datenschutz/datenschutz";
import Impressum from "./pages/impressum/impressum";
import Gallery from "./pages/gallery/gallery";
import Article from "./pages/article/article";
import AboutUs from "./pages/aboutUs/aboutUs";
import NotFound from "./pages/notfound/notfound";

function App() {
  

  return (
    <Router>
      <Navbar />
      <Routes>
        <Route  path="*" element={<NotFound />} />
        <Route path="/" element={<Home />} />
        <Route path="/berichte" element={<Article />} />
        <Route path="/galerie" element={<Gallery />} />
        <Route path="/datenschutz" element={<Datenschutz />} />
        <Route path="/impressum" element={<Impressum />} />
        <Route path="/überuns" element={<AboutUs />} />
      </Routes>
      <Footer />
    </Router>
  );
}

export default App;
