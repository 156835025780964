import React, { useEffect, useState } from "react";
import ReactMarkdown from "react-markdown";
import "./aboutUs.css";
import { useTranslation } from "react-i18next";
import MyBook from "../../components/flipbook/flipbook";

function AboutUs() {
  const [markdownContent, setMarkdownContent] = useState({});
  const { i18n } = useTranslation();

  useEffect(() => {
    const fetchContent = async () => {
      try {
        const response = await fetch(
          "/server/api/texts/?format=json&name=aboutUs"
        );
        if (!response.ok) {
          throw new Error("Failed to fetch content");
        }
        const data = await response.json();
        setMarkdownContent(data.length > 0 ? data[0] : {});
      } catch (error) {
        console.error("Error fetching content:", error);
      }
    };

    fetchContent();
  }, []);

  return (
    <div className="gallery">
      <h1 className="custom-header">
        <span className="red-text">Tübi</span>
        <span className="green-text">nger</span>
        <br />
        <span className="red-text">Neckar</span>
        <span className="green-text">Segler</span>
        <br />
        <span className="red-text">1957</span>
        <span className="green-text">e.V.</span>
        <br />
      </h1>

      <ReactMarkdown
        className="aboutUs"
        children={
          i18n.language.includes("en")
            ? markdownContent.text_en
            : markdownContent.text
        }
      />

      <MyBook />
    </div>
  );
}

export default AboutUs;
