import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";

function Article() {
  const { t } = useTranslation();
  const [articles, setArticles] = useState([]);
  const [error, setError] = useState(null);

  useEffect(() => {
    async function fetchData() {
      try {
        const response = await fetch(
          "/server/api/articles/?format=json"
        );
        if (response.ok) {
          const data = await response.json();
          console.log("Data:", data); // Log the data to check its structure
          setArticles(data);
        } else {
          console.error("Failed to fetch data:", response.status);
          setError("Failed to fetch data");
        }
      } catch (error) {
        console.error("Error:", error);
        setError("Error occurred");
      }
    }

    fetchData();
  }, []);

  if (error) {
    return <div className="error">Error: {error}</div>;
  }

  return (
    <div className="imprint">
      <h1>{t("navbar.article")}</h1>
      <ul>
        {articles.map((article, index) => (
          <li key={index}>
            <a href={article.pdf} target="_blank" rel="noopener noreferrer">
              {article.name}
            </a>
         
          </li>
        ))}
      </ul>
    </div>
  );
}

export default Article;
