import React from "react";
import "./footer.css";
import { Link, useLocation } from "react-router-dom";
import { useTranslation } from "react-i18next";

function Footer() {
  const [t] = useTranslation();
  const location = useLocation();

  const links = [
    { to: "/", label: "footer.home" },
    { to: "/%C3%BCberuns", label: "navbar.aboutUs" },
    { to: "/berichte", label: "navbar.article" },
    { to: "/galerie", label: "footer.gallery" },
    { href: "mailto:kontakt@neckarsegler.de", label: "footer.contact" },
    { to: "/impressum", label: "footer.imprint" },
    { to: "/datenschutz", label: "footer.privacyPolicy" },
  ];


  const scrollToTop = (event) => {
    setTimeout(() => {
      window.scrollTo(0,0);
    }, 50);
  };

  const handleLinkClick = (path) => {
    scrollToTop(); // Scroll to the top before navigating
  };

  return (
    <>
      <footer>
        <img src="img/icon.png" alt="logo"></img>
        <div className="link">
          <h5>{t("footer.links")}</h5>
          <ul>
            {links.map((link, index) => (
              <li key={index}>
                {link.to ? (
                  <Link
                    className={
                      location.pathname === link.to ? "activeLink" : ""
                    }
                    to={link.to}
                    onClick={() => handleLinkClick(link.to)}
                  >
                    {t(link.label)}
                  </Link>
                ) : (
                  <a href={link.href} onClick={() => handleLinkClick(link.href)}>
                    {t(link.label)}
                  </a>
                )}
              </li>
            ))}     
          </ul>
        </div>
      </footer>
      <div className="buttom-row">
        Copyright © {new Date().getFullYear()} {t("home.header.h1")} -{" "}
        {t("footer.rightsReserved")}
      </div>
    </>
  );
}

export default Footer;
